.header
{
  width: 100%;
  float: left;
}
.text
{
  width: 90%;
  margin: 0;
  margin-top: 40vh;
  margin-left: 5%;
  float: left;
  font-size: 3vw;
  text-align: center;
  padding: 1vw;
  background: radial-gradient(circle, rgba(0,0,0,0.7) 30%, rgba(0,0,0,0) 100%);
}

.text_small
{
  width: 50%;
  margin: 0;
  margin-top: 1vw;
  margin-left: 25%;
  float: left;
  font-size: 1.5vw;
  text-align: center;
  padding: 1vw;
  background: radial-gradient(circle, rgba(0,0,0,0.7) 30%, rgba(0,0,0,0) 100%);
}
.text_link
{
  width: 50%;
  margin: 0;
  margin-top: 1vw;
  margin-left: 25%;
  float: left;
  text-align: center;
  cursor: pointer;
  padding: 1vw;
  color:White;
  background: radial-gradient(circle, rgba(0,0,0,0.7) 30%, rgba(0,0,0,0) 100%);
}
.text_link:hover
{
  text-decoration: underline;
}

.segment
{
  position:  relative;
}
.background
{
  background-size: cover;
  background-position: center;
  position: absolute;
  width: 100%;
  height: 100%;
}
.logo
{
  position: absolute;
  float: left;
  z-index: 1;
  width:10%;
  margin-left: 45%;
  margin-top: 36vh;
}
